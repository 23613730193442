import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h1',{staticClass:"mt-4 text-center",class:{
                    'text-h2': _vm.$vuetify.breakpoint.lgAndUp,
                    'text-h3': !_vm.$vuetify.breakpoint.lgAndUp,
                }},[_vm._v(" Dstv "),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.packageTitle))]),_vm._v(" Channel List ")])])],1),_c(VRow,{staticClass:"mb-n8 mt-4"},[_c(VCol,{attrs:{"cols":"12","lg":"4","offset-lg":"4"}},[_c(VSelect,{staticClass:"mb-4",attrs:{"outlined":"","items":_vm.dstvCategoryList,"label":"Channel Category"},on:{"change":_vm.categoryChanged},model:{value:(_vm.dstvCategory),callback:function ($$v) {_vm.dstvCategory=$$v},expression:"dstvCategory"}})],1)],1),_c(VRow,{staticClass:"mt-n4"},_vm._l((_vm.channelList),function(channel){return _c(VCol,{key:channel.channel,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('dstv-channel',{attrs:{"item":channel}})],1)}),1),_c(VRow,{staticClass:"mt-8 mb-5"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }