import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"2"}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.item.name))]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.item.category))]),_c(VCardText,{staticClass:"overline mt-n2 mb-n1"},[_vm._v("Chanel "+_vm._s(_vm.item.channel))])],1),(_vm.item.logo)?_c(VAvatar,{staticClass:"ma-3 mt-2 mr-4",attrs:{"size":"100","tile":""}},[_c(VImg,{attrs:{"src":_vm.item.logo,"max-height":"75"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }