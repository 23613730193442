





















import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiViewList } from "@mdi/js";
import { Location } from "vue-router";

export interface DstvPackageItem {
    name: string;
    channels: string;
    price: number;
    route: Location;
}

@Component
export default class DstvPackage extends Vue {
    @Prop({ required: true, type: Object }) item!: DstvPackageItem;
    listIcon: string = mdiViewList;
}
